var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#be63c7","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Actualizar","back-button-text":"Atras","next-button-text":"Siguiente"},on:{"on-complete":_vm.confirmText}},[_c('tab-content',{attrs:{"title":"Información básica","before-change":_vm.validationInformacionBasica}},[_c('validation-observer',{ref:"validacionInfoBasica"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":_vm.imageData,"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v("Perfil")]),_c('b-form-file',{ref:"file-input",staticClass:"mb-2",attrs:{"placeholder":"click para seleccionar una imagen","browse-text":"Cargar"},on:{"change":_vm.previewImage}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Información básica")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingresa todos los campos, si tienes (*) Son obligatorios")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Tipo de documento","label-for":"tipo_documento"}},[_c('validation-provider',{attrs:{"name":"tipo documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"tipo_documento","state":errors.length > 0 ? false : null,"options":_vm.tipo_documento,"placeholder":""},model:{value:(_vm.form.code_tipo_documento),callback:function ($$v) {_vm.$set(_vm.form, "code_tipo_documento", $$v)},expression:"form.code_tipo_documento"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Numero de Cedula","label-for":"v-numero_cedula"}},[_c('validation-provider',{attrs:{"name":"numero_cedula","rules":"required|min:5|max:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-numero_cedula","disabled":"","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Escribe tu número de cedula"},model:{value:(_vm.form.numero_cedula),callback:function ($$v) {_vm.$set(_vm.form, "numero_cedula", $$v)},expression:"form.numero_cedula"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" El numero debe tener entre 5 a 14 numeros ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"* Nombre  Completo","label-for":"v-primer_nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-primer_nombre","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Escribe tu nombre"},model:{value:(_vm.form.primer_nombre),callback:function ($$v) {_vm.$set(_vm.form, "primer_nombre", $$v)},expression:"form.primer_nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Seleccionar paises"}},[_c('validation-provider',{attrs:{"name":"pais","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.paises,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null,"disabled-field":"notEnabled"},on:{"change":function($event){return _vm.CambiarPais()}},model:{value:(_vm.form.codigo_pais),callback:function ($$v) {_vm.$set(_vm.form, "codigo_pais", $$v)},expression:"form.codigo_pais"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Seleccionar estados"}},[_c('validation-provider',{attrs:{"name":"estados","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.estados,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null,"disabled-field":"notEnabled"},on:{"change":function($event){return _vm.getCiudades()}},model:{value:(_vm.form.codigo_estado),callback:function ($$v) {_vm.$set(_vm.form, "codigo_estado", $$v)},expression:"form.codigo_estado"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Seleccionar ciudades"}},[_c('validation-provider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.ciudades,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null,"disabled-field":"notEnabled"},model:{value:(_vm.form.codigo_ciudad),callback:function ($$v) {_vm.$set(_vm.form, "codigo_ciudad", $$v)},expression:"form.codigo_ciudad"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"","label-for":"v-whatsapp"}},[_c('validation-provider',{attrs:{"name":"whatsApp","rules":"required|min:4|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" * WhatsApp "),_c('vue-phone-number-input',{attrs:{"default-country-code":"CO","translations":{
                        countrySelectorLabel: 'Codigo pais',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de WhatsApp',
                        example: 'Ejemplo :',
                      }},on:{"update":function($event){_vm.results = $event}},model:{value:(_vm.form.whatsApp),callback:function ($$v) {_vm.$set(_vm.form, "whatsApp", $$v)},expression:"form.whatsApp"}}),_c('b-form-checkbox',{staticStyle:{"float":"right"},attrs:{"id":"llenar_cel","name":"llenar_cel"},on:{"change":function($event){return _vm.chekiar()}},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" Autocompletar celular ")]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Telefono de contacto","label-for":"v-celular"}},[_c('validation-provider',{attrs:{"name":"celular|min:13","rules":"required|min:4|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vue-phone-number-input',{attrs:{"default-country-code":"CO","translations":{
                        countrySelectorLabel: 'Codigo pais',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de Celular',
                        example: 'Ejemplo :',
                      }},on:{"update":function($event){_vm.results = $event}},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Correo","label-for":"v-correo"}},[_c('validation-provider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-correo","disabled":"","type":"email","placeholder":"Escribe tu correo electronico","state":errors.length > 0 ? false : null},model:{value:(_vm.correo),callback:function ($$v) {_vm.correo=$$v},expression:"correo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }